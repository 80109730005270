import MetricComparison from "../types/MetricComparison";
import { increaseIsPositive } from "../utils/compare";
import { percentage } from "../utils/percentage";
import { round2points } from "../utils/round";
import { DataPoints, Metrics } from "./index";
import Metric from "./Metric";

class GenderDiversity extends Metric {
    public static metric: Metrics = Metrics.GenderDiversity;
    public data: {
        [DataPoints.FemaleBoardMembers]?: number;
        [DataPoints.FemaleManagementMembers]?: number;
        [DataPoints.TotalBoardMembers]?: number;
        [DataPoints.TotalManagementMembers]?: number;
    };

    protected setScore() {
        const femaleBoardMembers = this.data[DataPoints.FemaleBoardMembers] ?? 0;
        const femaleManagementMembers = this.data[DataPoints.FemaleManagementMembers] ?? 0;
        const totalBoardMembers = this.data[DataPoints.TotalBoardMembers] ?? 0;
        const totalManagementMembers = this.data[DataPoints.TotalManagementMembers] ?? 0;

        if (totalBoardMembers || totalManagementMembers) {
            this.score = percentage(
                femaleBoardMembers + femaleManagementMembers,
                totalBoardMembers + totalManagementMembers,
            );
        }
    }

    public renderScore(): string {
        if (this.score === undefined) {
            return "-";
        }

        return `${round2points(this.score * 100)}%`;
    }

    public renderDifference(comparison: MetricComparison) {
        if (comparison.difference === undefined || comparison.difference === 0) {
            return null;
        }

        return `${round2points(comparison.difference * 100)}%`;
    }

    public compare(metric: Metric): MetricComparison {
        return increaseIsPositive(this, metric);
    }

    public getManagementDiversity() {
        const femaleManagementMembers = this.data[DataPoints.FemaleManagementMembers];
        const totalManagementMembers = this.data[DataPoints.TotalManagementMembers];

        if (femaleManagementMembers === undefined || totalManagementMembers === undefined) {
            return undefined;
        }

        return percentage(femaleManagementMembers, totalManagementMembers);
    }

    public getBoardDiversity() {
        const femaleBoardMembers = this.data[DataPoints.FemaleBoardMembers];
        const totalBoardMembers = this.data[DataPoints.TotalBoardMembers];

        if (femaleBoardMembers === undefined || totalBoardMembers === undefined) {
            return undefined;
        }

        return percentage(femaleBoardMembers, totalBoardMembers);
    }
}

export default GenderDiversity;
