export enum Metrics {
    RenewableEnergyConsumption = "renewable-energy-consumption",
    EmissionsIntensity = "ghg-emissions-intensity",
    CommunityEngagement = "community-engagement",
    CustomerSatisfaction = "customer-satisfaction",
    EmployeeSatisfaction = "employee-satisfaction",
    GenderDiversity = "gender-diversity",
    IncidentFrequency = "incident-frequency",
    TeamSize = "team-size",
    BoardMaturity = "board-maturity",
    CyberSecurityMaturity = "cyber-security-maturity",
}

export enum DataPoints {
    RenewableEnergyPercentage = "renewable-energy-percentage",
    TotalFacilityEnergyConsumption = "total-facility-energy",
    RenewableFacilityEnergyConsumption = "renewable-facility-energy",
    TotalVehicleEnergyConsumption = "total-vehicle-energy",
    RenewableVehicleEnergyConsumption = "renewable-vehicle-energy",
    TotalRefrigerantEnergyConsumption = "total-refrigerant-energy",
    RenewableRefrigerantEnergyConsumption = "renewable-refrigerant-energy",
    TotalElectricityEnergyConsumption = "total-electricity-energy",
    RenewableElectricityEnergyConsumption = "renewable-electricity-energy",
    TotalBusinessTravelEnergyConsumption = "total-business-travel-energy",
    RenewableBusinessTravelEnergyConsumption = "renewable-business-travel-energy",
    TotalScope1And2Emissions = "total-scope-1-2-emissions",
    TotalFacilityEmissions = "total-facility-emissions",
    TotalVehicleEmissions = "total-vehicle-emissions",
    TotalRefrigerantEmissions = "total-refrigerant-emissions",
    TotalElectricityEmissions = "total-electricity-emissions",
    TotalBusinessTravelEmissions = "total-business-travel-emissions",
    AnnualRevenue = "annual-revenue",
    AnnualRevenueExchange = "annual-revenue-exchange",
    CommunityEngagements = "community-engagements",
    NetPromoterScore = "nps",
    CustomerSurvey = "customer-survey",
    EmployeeNetPromoterScore = "enps",
    EmployeeSurvey = "employee-survey",
    TotalBoardMembers = "total-board-members",
    FemaleBoardMembers = "female-board-members",
    TotalManagementMembers = "total-management-members",
    FemaleManagementMembers = "female-management-members",
    TotalIncidents = "total-incidents",
    TotalInjuries = "total-injuries",
    TotalFatalities = "total-fatalities",

    FteEmployees2020 = "fte-employees-2020",
    FteEmployees2021 = "fte-employees-2021",
    FteEmployees2022 = "fte-employees-2022",

    BoardMaturityMaxScore = "board-maturity-max-score",

    BoardMaxScore = "board-max-score",
    BoardChairCeo = "board-chair-ceo",
    BoardSkillsMatrix = "board-skills-matrix",
    BoardAssessment = "board-assessment",
    BoardChairAgenda = "board-chair-agenda",
    BoardDiversityTargets = "board-diversity-targets",
    BoardESGTraining = "board-esg-training",
    BoardBusinessTime = "board-business-time",

    ManagementMaxScore = "management-max-score",
    AnnualStrategySetting = "annual-strategy-setting",
    PurposeESGVision = "purpose-esg-vision",
    StrategicGoalReview = "strategic-goal-review",
    CeoSuccessionPlan = "ceo-succession-plan",
    SuccessionPlanTransition = "succession-plan-transition",
    SuccessionPlanReview = "succession-plan-review",

    TalentCompensationMaxScore = "talent-compensation-max-score",
    TalentReview = "talent-review",
    TalentSuccessionPlan = "talent-succession-plan",
    CompensationCommittee = "compensation-committee",
    CeoCompensation = "ceo-compensation",
    ESGManagementCompensation = "esg-management-compensation",

    PoliciesProceduresMaxScore = "policies-procedures-max-score",
    BoardPoliciesProcedures = "board-policies-procedures",
    ComplianceManual = "compliance-manual",
    CodeOfConductEthics = "code-of-conduct-ethics",
    InformationSafeguarding = "information-safeguarding",
    ConflictOfInterests = "conflict-of-interests",
    PolicyBreaches = "policy-breaches",

    ControlRiskMaxScore = "control-risk-max-score",
    RiskManagementSystem = "risk-management-system",
    RiskPriorityReview = "risk-priority-review",
    PriorityRiskReview = "priority-risk-review",
    RiskAssessment = "risk-assessment",
    InternalControlChange = "internal-control-change",
    ChiefRiskOfficer = "chief-risk-officer",
    ChiefRiskOfficerReports = "chief-risk-officer-reports",

    AuditDisclosureMaxScore = "audit-disclosure-max-score",
    AuditCommittee = "audit-committee",
    AuditCommitteeFrequency = "audit-committee-frequency",
    AuditCommitteeReports = "audit-committee-reports",
    CertifiedAccountant = "certified-accountant",
    ESGDiscussion = "esg-discussion",
    ESGReports = "esg-reports",

    ESGMaxScore = "esg-max-score",
    ESGStrategyPlanning = "esg-planning",
    ESGStrategyReview = "esg-review",
    ESGAssessment = "esg-assessment",

    CyberSecurityMaxScore = "cybersecurity-maturity-max-score",
    CyberSecurityPolicy = "cybersecurity-policy",
    CyberSecurityPolicyStandard = "cybersecurity-policy-standard",
    CyberSecurityPolicyApproval = "cybersecurity-policy-approval",
    CyberSecurityPolicyReview = "cybersecurity-policy-review",
    CyberSecurityBreaches = "cybersecurity-breaches",
    PenetrationTesting = "penetration-testing",
    VulnerabilityTesting = "vulnerability-testing",
    CyberSecurityTraining = "cybersecurity-training",
    BusinessContinuityPlan = "business-continuity-plan",
    DisasterRecoveryPlan = "disaster-recovery-plan",
    DataSecurityAssessment = "data-security-assessment",
    DataEncryption = "data-encryption",
}
