import MetricComparison from "../types/MetricComparison";
import { increaseIsPositive } from "../utils/compare";
import { DataPoints, Metrics } from "./index";
import Metric from "./Metric";

class TeamSize extends Metric {
    public static metric: Metrics = Metrics.TeamSize;
    public data: {
        [DataPoints.FteEmployees2020]?: number;
        [DataPoints.FteEmployees2021]?: number;
        [DataPoints.FteEmployees2022]?: number;
    };

    protected setScore() {
        //
    }

    public compare(metric: Metric): MetricComparison {
        return increaseIsPositive(this, metric);
    }

    public getPreviousYearFTEs() {
        const ftes2020 = this.data[DataPoints.FteEmployees2020];
        const ftes2021 = this.data[DataPoints.FteEmployees2021];
        const ftes2022 = this.data[DataPoints.FteEmployees2022];

        if (ftes2020 && ftes2021) {
            return ftes2020;
        }

        if (ftes2021 && ftes2022) {
            return ftes2021;
        }
    }

    public getCurrentYearFTEs() {
        const ftes2020 = this.data[DataPoints.FteEmployees2020];
        const ftes2021 = this.data[DataPoints.FteEmployees2021];
        const ftes2022 = this.data[DataPoints.FteEmployees2022];

        if (ftes2020 && ftes2021) {
            return ftes2021;
        }

        if (ftes2021 && ftes2022) {
            return ftes2022;
        }
    }
}

export default TeamSize;
