import { Result, ResultSet } from "@recoinsights/types";

import { Metrics } from "../metrics";
import BoardMaturity from "../metrics/BoardMaturity";
import CommunityEngagement from "../metrics/CommunityEngagement";
import CustomerSatisfaction from "../metrics/CustomerSatisfaction";
import CyberSecurityMaturity from "../metrics/CyberSecurityMaturity";
import EmissionsIntensity from "../metrics/EmissionsIntensity";
import EmployeeSatisfaction from "../metrics/EmployeeSatisfaction";
import GenderDiversity from "../metrics/GenderDiversity";
import IncidentFrequency from "../metrics/IncidentFrequency";
import RenewableEnergyConsumption from "../metrics/RenewableEnergyConsumption";
import TeamSize from "../metrics/TeamSize";

export function resolveMetricResults(key: Metrics, resultSet: ResultSet) {
    return resolveMetric(key, resultSet.metrics[key]);
}

export function resolveMetric(key: Metrics, result?: Result) {
    switch (key) {
        case Metrics.RenewableEnergyConsumption:
            return new RenewableEnergyConsumption(result);
        case Metrics.EmissionsIntensity:
            return new EmissionsIntensity(result);
        case Metrics.CommunityEngagement:
            return new CommunityEngagement(result);
        case Metrics.CustomerSatisfaction:
            return new CustomerSatisfaction(result);
        case Metrics.EmployeeSatisfaction:
            return new EmployeeSatisfaction(result);
        case Metrics.GenderDiversity:
            return new GenderDiversity(result);
        case Metrics.IncidentFrequency:
            return new IncidentFrequency(result);
        case Metrics.TeamSize:
            return new TeamSize(result);
        case Metrics.BoardMaturity:
            return new BoardMaturity(result);
        case Metrics.CyberSecurityMaturity:
            return new CyberSecurityMaturity(result);
    }
}
