import MetricComparison from "../types/MetricComparison";
import { decreaseIsPositive } from "../utils/compare";
import { round2points } from "../utils/round";
import { DataPoints, Metrics } from "./index";
import Metric from "./Metric";

class EmissionsIntensity extends Metric {
    public static metric: Metrics = Metrics.EmissionsIntensity;
    public data: {
        [DataPoints.TotalScope1And2Emissions]?: number;
        [DataPoints.TotalFacilityEmissions]?: number;
        [DataPoints.TotalVehicleEmissions]?: number;
        [DataPoints.TotalRefrigerantEmissions]?: number;
        [DataPoints.TotalElectricityEmissions]?: number;
        [DataPoints.TotalBusinessTravelEmissions]?: number;
        [DataPoints.AnnualRevenue]?: number;
        [DataPoints.AnnualRevenueExchange]?: number;
    };

    protected setScore() {
        const annualRevenueDollars = this.getAnnualRevenueDollars();
        let scope1and2EmissionsKg = this.data[DataPoints.TotalScope1And2Emissions];

        if (!scope1and2EmissionsKg) {
            const scope1EmissionsKg = this.getScope1Emissions() ?? 0;
            const scope2EmissionsKg = this.getScope2Emissions() ?? 0;

            scope1and2EmissionsKg = scope1EmissionsKg + scope2EmissionsKg;
        }

        if (scope1and2EmissionsKg && annualRevenueDollars) {
            const scope1and2EmissionsTonnes = scope1and2EmissionsKg / 1000;
            const annualRevenueMillionDollars = annualRevenueDollars / 1000000;

            this.score = scope1and2EmissionsTonnes / annualRevenueMillionDollars;
        }
    }

    public renderScore(): string {
        if (this.score === undefined) {
            return "-";
        }

        return `${round2points(this.score)}`;
    }

    public renderDifference(comparison: MetricComparison) {
        if (comparison.difference === undefined || comparison.difference === 0) {
            return null;
        }

        return `${round2points(comparison.difference)}`;
    }

    public compare(metric: Metric): MetricComparison {
        return decreaseIsPositive(this, metric);
    }

    public getAnnualRevenueDollars(): number | undefined {
        const annualRevenueExchange = this.data[DataPoints.AnnualRevenueExchange];
        const annualRevenue = this.data[DataPoints.AnnualRevenue];
        let annualRevenueDollars = annualRevenue;

        if (annualRevenue && annualRevenueExchange) {
            annualRevenueDollars = annualRevenue * annualRevenueExchange;
        }

        return annualRevenueDollars;
    }

    public getScope1Emissions(): number | undefined {
        let scope1Emissions: number | undefined = undefined;

        [
            DataPoints.TotalFacilityEmissions,
            DataPoints.TotalVehicleEmissions,
            DataPoints.TotalRefrigerantEmissions,
        ].forEach((dataPoint) => {
            const value = this.data[dataPoint];

            if (value) {
                scope1Emissions = (scope1Emissions ?? 0) + value;
            }
        });

        return scope1Emissions;
    }

    public getScope2Emissions() {
        return this.data[DataPoints.TotalElectricityEmissions];
    }

    public getScope3Emissions() {
        return this.data[DataPoints.TotalBusinessTravelEmissions];
    }
}

export default EmissionsIntensity;
