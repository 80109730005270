import MetricComparison from "../types/MetricComparison";
import { increaseIsPositive } from "../utils/compare";
import { percentage } from "../utils/percentage";
import { round2points } from "../utils/round";
import { DataPoints, Metrics } from "./index";
import Metric from "./Metric";

class CyberSecurityMaturity extends Metric {
    public static metric: Metrics = Metrics.CyberSecurityMaturity;
    public data: {
        [DataPoints.CyberSecurityMaxScore]?: number;
        [DataPoints.CyberSecurityPolicy]?: number;
        [DataPoints.CyberSecurityPolicyStandard]?: number;
        [DataPoints.CyberSecurityPolicyApproval]?: number;
        [DataPoints.CyberSecurityPolicyReview]?: number;
        [DataPoints.CyberSecurityBreaches]?: number;
        [DataPoints.PenetrationTesting]?: number;
        [DataPoints.VulnerabilityTesting]?: number;
        [DataPoints.CyberSecurityTraining]?: number;
        [DataPoints.BusinessContinuityPlan]?: number;
        [DataPoints.DisasterRecoveryPlan]?: number;
        [DataPoints.DataSecurityAssessment]?: number;
        [DataPoints.DataEncryption]?: number;
    };

    protected setScore() {
        const max = this.data[DataPoints.CyberSecurityMaxScore];
        let score: number | undefined = undefined;

        [
            DataPoints.CyberSecurityPolicy,
            DataPoints.CyberSecurityPolicyStandard,
            DataPoints.CyberSecurityPolicyApproval,
            DataPoints.CyberSecurityPolicyReview,
            DataPoints.CyberSecurityBreaches,
            DataPoints.PenetrationTesting,
            DataPoints.VulnerabilityTesting,
            DataPoints.CyberSecurityTraining,
            DataPoints.BusinessContinuityPlan,
            DataPoints.DisasterRecoveryPlan,
            DataPoints.DataSecurityAssessment,
            DataPoints.DataEncryption,
        ].forEach((dataPoint) => {
            const value = this.data[dataPoint];

            if (value === undefined) {
                return;
            }

            score = (score ?? 0) + value;
        });

        if (score === undefined || max === undefined) {
            return;
        }

        this.score = percentage(score, max);
    }

    public renderScore(): string {
        if (this.score === undefined) {
            return "-";
        }

        return `${round2points(this.score * 100)}%`;
    }

    public renderDifference(comparison: MetricComparison) {
        if (comparison.difference === undefined || comparison.difference === 0) {
            return null;
        }

        return `${round2points(comparison.difference * 100)}%`;
    }

    public compare(metric: Metric): MetricComparison {
        return increaseIsPositive(this, metric);
    }
}

export default CyberSecurityMaturity;
