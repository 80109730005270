import MetricComparison from "../types/MetricComparison";
import { increaseIsPositive } from "../utils/compare";
import { percentage } from "../utils/percentage";
import { round2points } from "../utils/round";
import { DataPoints, Metrics } from "./index";
import Metric from "./Metric";

interface Breakdown {
    dataPoints: DataPoints[];
    max?: number;
    score?: number;
    total?: number;
}

class BoardMaturity extends Metric {
    public static metric: Metrics = Metrics.BoardMaturity;
    public data: {
        [DataPoints.BoardMaturityMaxScore]?: number;

        [DataPoints.BoardMaxScore]?: number;
        [DataPoints.BoardChairCeo]?: number;
        [DataPoints.BoardSkillsMatrix]?: number;
        [DataPoints.BoardAssessment]?: number;
        [DataPoints.BoardChairAgenda]?: number;
        [DataPoints.BoardDiversityTargets]?: number;
        [DataPoints.BoardESGTraining]?: number;
        [DataPoints.BoardBusinessTime]?: number;

        [DataPoints.ManagementMaxScore]?: number;
        [DataPoints.AnnualStrategySetting]?: number;
        [DataPoints.PurposeESGVision]?: number;
        [DataPoints.StrategicGoalReview]?: number;
        [DataPoints.CeoSuccessionPlan]?: number;
        [DataPoints.SuccessionPlanTransition]?: number;
        [DataPoints.SuccessionPlanReview]?: number;

        [DataPoints.TalentCompensationMaxScore]?: number;
        [DataPoints.TalentReview]?: number;
        [DataPoints.TalentSuccessionPlan]?: number;
        [DataPoints.CompensationCommittee]?: number;
        [DataPoints.CeoCompensation]?: number;
        [DataPoints.ESGManagementCompensation]?: number;

        [DataPoints.PoliciesProceduresMaxScore]?: number;
        [DataPoints.BoardPoliciesProcedures]?: number;
        [DataPoints.ComplianceManual]?: number;
        [DataPoints.CodeOfConductEthics]?: number;
        [DataPoints.InformationSafeguarding]?: number;
        [DataPoints.ConflictOfInterests]?: number;
        [DataPoints.PolicyBreaches]?: number;

        [DataPoints.ControlRiskMaxScore]?: number;
        [DataPoints.RiskManagementSystem]?: number;
        [DataPoints.RiskPriorityReview]?: number;
        [DataPoints.PriorityRiskReview]?: number;
        [DataPoints.RiskAssessment]?: number;
        [DataPoints.InternalControlChange]?: number;
        [DataPoints.ChiefRiskOfficer]?: number;
        [DataPoints.ChiefRiskOfficerReports]?: number;

        [DataPoints.AuditDisclosureMaxScore]?: number;
        [DataPoints.AuditCommittee]?: number;
        [DataPoints.AuditCommitteeFrequency]?: number;
        [DataPoints.AuditCommitteeReports]?: number;
        [DataPoints.CertifiedAccountant]?: number;
        [DataPoints.ESGDiscussion]?: number;
        [DataPoints.ESGReports]?: number;

        [DataPoints.ESGMaxScore]?: number;
        [DataPoints.ESGStrategyPlanning]?: number;
        [DataPoints.ESGStrategyReview]?: number;
        [DataPoints.ESGAssessment]?: number;
    };

    protected setScore() {
        const { total, max } = this.getScoreBreakdown();

        if (max === undefined || total === undefined) {
            return;
        }

        this.score = percentage(total, max);
    }

    public renderScore(): string {
        if (this.score === undefined) {
            return "-";
        }

        return `${round2points(this.score * 100)}%`;
    }

    public renderDifference(comparison: MetricComparison) {
        if (comparison.difference === undefined || comparison.difference === 0) {
            return null;
        }

        return `${round2points(comparison.difference * 100)}%`;
    }

    public compare(metric: Metric): MetricComparison {
        return increaseIsPositive(this, metric);
    }

    public getScoreBreakdown() {
        const max = this.data[DataPoints.BoardMaturityMaxScore];
        let total: number | undefined = undefined;

        const board: Breakdown = {
            dataPoints: [
                DataPoints.BoardChairCeo,
                DataPoints.BoardSkillsMatrix,
                DataPoints.BoardAssessment,
                DataPoints.BoardChairAgenda,
                DataPoints.BoardDiversityTargets,
                DataPoints.BoardESGTraining,
                DataPoints.BoardBusinessTime,
            ],
            max: this.data[DataPoints.BoardMaxScore],
        };

        const management: Breakdown = {
            dataPoints: [
                DataPoints.AnnualStrategySetting,
                DataPoints.PurposeESGVision,
                DataPoints.StrategicGoalReview,
                DataPoints.CeoSuccessionPlan,
                DataPoints.SuccessionPlanTransition,
                DataPoints.SuccessionPlanReview,
            ],
            max: this.data[DataPoints.ManagementMaxScore],
        };

        const policiesProcedures: Breakdown = {
            dataPoints: [
                DataPoints.BoardPoliciesProcedures,
                DataPoints.ComplianceManual,
                DataPoints.CodeOfConductEthics,
                DataPoints.InformationSafeguarding,
                DataPoints.ConflictOfInterests,
                DataPoints.PolicyBreaches,
            ],
            max: this.data[DataPoints.PoliciesProceduresMaxScore],
        };

        const talentCompensation: Breakdown = {
            dataPoints: [
                DataPoints.TalentReview,
                DataPoints.TalentSuccessionPlan,
                DataPoints.CompensationCommittee,
                DataPoints.CeoCompensation,
                DataPoints.ESGManagementCompensation,
            ],
            max: this.data[DataPoints.TalentCompensationMaxScore],
        };

        const controlRisk: Breakdown = {
            dataPoints: [
                DataPoints.RiskManagementSystem,
                DataPoints.RiskPriorityReview,
                DataPoints.PriorityRiskReview,
                DataPoints.RiskAssessment,
                DataPoints.InternalControlChange,
                DataPoints.ChiefRiskOfficer,
                DataPoints.ChiefRiskOfficerReports,
            ],
            max: this.data[DataPoints.ControlRiskMaxScore],
        };

        const auditDisclosure: Breakdown = {
            dataPoints: [
                DataPoints.AuditCommittee,
                DataPoints.AuditCommitteeFrequency,
                DataPoints.AuditCommitteeReports,
                DataPoints.CertifiedAccountant,
                DataPoints.ESGDiscussion,
                DataPoints.ESGReports,
            ],
            max: this.data[DataPoints.AuditDisclosureMaxScore],
        };

        const esg: Breakdown = {
            dataPoints: [DataPoints.ESGStrategyPlanning, DataPoints.ESGStrategyReview, DataPoints.ESGAssessment],
            max: this.data[DataPoints.ESGMaxScore],
        };

        const categories = [
            board,
            management,
            policiesProcedures,
            talentCompensation,
            controlRisk,
            auditDisclosure,
            esg,
        ];

        categories.forEach((category) => {
            if (category.max === undefined) {
                return;
            }

            category.dataPoints.forEach((dataPoint) => {
                const value = this.data[dataPoint];

                if (value === undefined) {
                    return;
                }

                category.total = (category.total ?? 0) + value;
            });

            if (category.total === undefined) {
                return;
            }

            category.score = percentage(category.total, category.max);

            total = (total ?? 0) + category.total;
        });

        return {
            total,
            max,
            board,
            management,
            policiesProcedures,
            talentCompensation,
            controlRisk,
            auditDisclosure,
            esg,
        };
    }
}

export default BoardMaturity;
