import MetricComparison from "../types/MetricComparison";
import { increaseIsPositive } from "../utils/compare";
import { DataPoints, Metrics } from "./index";
import Metric from "./Metric";

class CommunityEngagement extends Metric {
    public static metric: Metrics = Metrics.CommunityEngagement;
    public data: {
        [DataPoints.CommunityEngagements]: number;
    };

    protected setScore() {
        const communityEngagements = this.data[DataPoints.CommunityEngagements];

        if (communityEngagements) {
            this.score = communityEngagements;
        }
    }

    public renderScore(): string {
        if (this.score === undefined) {
            return "-";
        }

        return this.score === 1 ? "Yes" : "No";
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public renderDifference(comparison: MetricComparison) {
        return null;
    }

    public compare(metric: Metric): MetricComparison {
        return increaseIsPositive(this, metric);
    }
}

export default CommunityEngagement;
