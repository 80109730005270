import MetricComparison from "../types/MetricComparison";
import { increaseIsPositive } from "../utils/compare";
import { percentageWithDecimals } from "../utils/percentage";
import { round2points } from "../utils/round";
import { DataPoints, Metrics } from "./index";
import Metric from "./Metric";

class RenewableEnergyConsumption extends Metric {
    public static metric: Metrics = Metrics.RenewableEnergyConsumption;
    public data: {
        [DataPoints.RenewableEnergyPercentage]?: number;
        [DataPoints.TotalFacilityEnergyConsumption]?: number;
        [DataPoints.RenewableFacilityEnergyConsumption]?: number;
        [DataPoints.TotalVehicleEnergyConsumption]?: number;
        [DataPoints.RenewableVehicleEnergyConsumption]?: number;
        [DataPoints.TotalRefrigerantEnergyConsumption]?: number;
        [DataPoints.RenewableRefrigerantEnergyConsumption]?: number;
        [DataPoints.TotalElectricityEnergyConsumption]?: number;
        [DataPoints.RenewableElectricityEnergyConsumption]?: number;
        [DataPoints.TotalBusinessTravelEnergyConsumption]?: number;
        [DataPoints.RenewableBusinessTravelEnergyConsumption]?: number;
    };

    protected setScore() {
        const totalEnergyConsumption = this.getTotalEnergyConsumption();
        const renewableEnergyConsumption = this.getRenewableEnergyConsumption();

        if (renewableEnergyConsumption !== undefined && totalEnergyConsumption) {
            this.score = percentageWithDecimals(renewableEnergyConsumption, totalEnergyConsumption);
        }
    }

    public renderScore(): string {
        if (this.score === undefined) {
            return "-";
        }

        return `${round2points(this.score * 100)}%`;
    }

    public renderDifference(comparison: MetricComparison) {
        if (comparison.difference === undefined || comparison.difference === 0) {
            return null;
        }

        return `${round2points(comparison.difference * 100)}%`;
    }

    public compare(metric: Metric): MetricComparison {
        return increaseIsPositive(this, metric);
    }

    public getTotalEnergyConsumption(): number | undefined {
        let totalEnergyConsumption: number | undefined = undefined;

        [
            DataPoints.TotalFacilityEnergyConsumption,
            DataPoints.TotalVehicleEnergyConsumption,
            DataPoints.TotalRefrigerantEnergyConsumption,
            DataPoints.TotalElectricityEnergyConsumption,
        ].forEach((dataPoint) => {
            const value = this.data[dataPoint];

            if (value) {
                totalEnergyConsumption = (totalEnergyConsumption ?? 0) + value;
            }
        });

        return totalEnergyConsumption;
    }

    public getRenewableEnergyConsumption(): number | undefined {
        const renewableEnergyPercentage = this.data[DataPoints.RenewableEnergyPercentage];
        const totalElectricityEnergyConsumption = this.data[DataPoints.TotalElectricityEnergyConsumption] ?? 0;
        let renewableEnergyConsumption: number | undefined = undefined;

        [
            DataPoints.RenewableFacilityEnergyConsumption,
            DataPoints.RenewableVehicleEnergyConsumption,
            DataPoints.RenewableRefrigerantEnergyConsumption,
            DataPoints.RenewableElectricityEnergyConsumption,
        ].forEach((dataPoint) => {
            let value = this.data[dataPoint];

            switch (dataPoint) {
                case DataPoints.RenewableElectricityEnergyConsumption:
                    if (renewableEnergyPercentage && totalElectricityEnergyConsumption) {
                        value = totalElectricityEnergyConsumption * (renewableEnergyPercentage / 100);
                    }

                    break;
            }

            if (value) {
                renewableEnergyConsumption = (renewableEnergyConsumption ?? 0) + value;
            }
        });

        return renewableEnergyConsumption;
    }
}

export default RenewableEnergyConsumption;
