import Metric from "../metrics/Metric";
import MetricComparison from "../types/MetricComparison";
import { number } from "./number";

export function compare(a: Metric, b: Metric) {
    const comparison: MetricComparison = {
        isIncreased: false,
        isDecreased: false,
        isPositive: false,
        isNegative: false,
    };

    if (a.score === undefined || b.score === undefined) {
        return comparison;
    }

    comparison.difference = number(a.score - b.score);

    if (a.score > b.score) {
        comparison.isIncreased = true;
    }

    if (a.score < b.score) {
        comparison.isDecreased = true;
        comparison.difference = comparison.difference * -1;
    }

    return comparison;
}

export function increaseIsPositive(a: Metric, b: Metric) {
    const comparison = compare(a, b);

    if (a.score === undefined || b.score === undefined) {
        return comparison;
    }

    if (a.score > b.score) {
        comparison.isPositive = true;
    }

    if (a.score < b.score) {
        comparison.isNegative = true;
    }

    return comparison;
}

export function decreaseIsPositive(a: Metric, b: Metric) {
    const comparison = compare(a, b);

    if (a.score === undefined || b.score === undefined) {
        return comparison;
    }

    if (a.score < b.score) {
        comparison.isPositive = true;
    }

    if (a.score > b.score) {
        comparison.isNegative = true;
    }

    return comparison;
}
