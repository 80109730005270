import MetricComparison from "../types/MetricComparison";
import { increaseIsPositive } from "../utils/compare";
import { DataPoints, Metrics } from "./index";
import Metric from "./Metric";

class EmployeeSatisfaction extends Metric {
    public static metric: Metrics = Metrics.EmployeeSatisfaction;
    public data: {
        [DataPoints.EmployeeSurvey]: number;
        [DataPoints.EmployeeNetPromoterScore]: number;
    };

    protected setScore() {
        const enps = this.data[DataPoints.EmployeeNetPromoterScore];

        if (enps !== undefined) {
            this.score = enps;
        }
    }

    public hasScore(): boolean {
        const employeeSurvey = this.data[DataPoints.EmployeeSurvey];

        return this.score !== undefined || employeeSurvey !== undefined;
    }

    public renderScore(): string {
        if (this.score !== undefined) {
            return `${this.score}`;
        }

        const employeeSurvey = this.data[DataPoints.EmployeeSurvey];

        if (employeeSurvey === undefined) {
            return "-";
        }

        return employeeSurvey ? "Yes" : "No";
    }

    public compare(metric: EmployeeSatisfaction): MetricComparison {
        const employeeSurveyA = this.data[DataPoints.EmployeeSurvey];
        const employeeSurveyB = metric.data[DataPoints.EmployeeSurvey];

        if (this.score !== undefined && metric.score !== undefined) {
            return increaseIsPositive(this, metric);
        }

        const comparison = {
            isIncreased: false,
            isDecreased: false,
            isPositive: false,
            isNegative: false,
        };

        if (employeeSurveyA === undefined || employeeSurveyB === undefined) {
            return comparison;
        }

        if (employeeSurveyA > employeeSurveyB || (this.score !== undefined && metric.score === undefined)) {
            comparison.isIncreased = true;
            comparison.isPositive = true;
        }

        if (employeeSurveyA < employeeSurveyB || (this.score === undefined && metric.score !== undefined)) {
            comparison.isDecreased = true;
            comparison.isNegative = true;
        }

        return comparison;
    }
}

export default EmployeeSatisfaction;
