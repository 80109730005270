import { Result } from "@recoinsights/types";

import MetricComparison from "../types/MetricComparison";
import { compare } from "../utils/compare";
import { DataPoints, Metrics } from "./index";

abstract class Metric implements Result {
    public static metric: Metrics;
    public data: Record<string, number | null>;
    public score?: number;

    constructor(result?: Result) {
        this.data = result?.data ?? {};
        this.score = result?.score;
    }

    public setData(key: DataPoints, value: number | null) {
        this.data[key] = value;

        this.setScore();
    }

    protected setScore() {
        //
    }

    public hasScore(): boolean {
        return this.score !== undefined;
    }

    public renderScore(): string {
        if (!this.hasScore()) {
            return "-";
        }

        return `${this.score}`;
    }

    public renderDifference(comparison: MetricComparison) {
        if (comparison.difference === undefined || comparison.difference === 0) {
            return null;
        }

        return `${comparison.difference}`;
    }

    public compare(metric: Metric): MetricComparison {
        return compare(this, metric);
    }
}

export default Metric;
