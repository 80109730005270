import MetricComparison from "../types/MetricComparison";
import { decreaseIsPositive } from "../utils/compare";
import { DataPoints, Metrics } from "./index";
import Metric from "./Metric";

class IncidentFrequency extends Metric {
    public static metric: Metrics = Metrics.IncidentFrequency;
    public data: {
        [DataPoints.TotalIncidents]?: number;
        [DataPoints.TotalInjuries]?: number;
        [DataPoints.TotalFatalities]?: number;
    };

    protected setScore() {
        let totalIncidents = this.data[DataPoints.TotalIncidents];
        const totalInjuries = this.data[DataPoints.TotalInjuries] ?? 0;
        const totalFatalities = this.data[DataPoints.TotalFatalities] ?? 0;

        if (!totalIncidents) {
            totalIncidents = totalInjuries + totalFatalities;
        }

        if (totalIncidents) {
            this.score = Math.round((totalIncidents / 12) * 100) / 100;
        }
    }

    public compare(metric: Metric): MetricComparison {
        return decreaseIsPositive(this, metric);
    }
}

export default IncidentFrequency;
