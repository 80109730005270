import MetricComparison from "../types/MetricComparison";
import { increaseIsPositive } from "../utils/compare";
import { DataPoints, Metrics } from "./index";
import Metric from "./Metric";

class CustomerSatisfaction extends Metric {
    public static metric: Metrics = Metrics.CustomerSatisfaction;
    public data: {
        [DataPoints.CustomerSurvey]: number;
        [DataPoints.NetPromoterScore]: number;
    };

    protected setScore() {
        const nps = this.data[DataPoints.NetPromoterScore];

        if (nps !== undefined) {
            this.score = nps;
        }
    }

    public hasScore(): boolean {
        const customerSurvey = this.data[DataPoints.CustomerSurvey];

        return this.score !== undefined || customerSurvey !== undefined;
    }

    public renderScore(): string {
        if (this.score !== undefined) {
            return `${this.score}`;
        }

        const customerSurvey = this.data[DataPoints.CustomerSurvey];

        if (customerSurvey === undefined) {
            return "-";
        }

        return customerSurvey ? "Yes" : "No";
    }

    public compare(metric: CustomerSatisfaction): MetricComparison {
        const customerSurveyA = this.data[DataPoints.CustomerSurvey];
        const customerSurveyB = metric.data[DataPoints.CustomerSurvey];

        if (this.score !== undefined && metric.score !== undefined) {
            return increaseIsPositive(this, metric);
        }

        const comparison = {
            isIncreased: false,
            isDecreased: false,
            isPositive: false,
            isNegative: false,
        };

        if (customerSurveyA === undefined || customerSurveyB === undefined) {
            return comparison;
        }

        if (customerSurveyA > customerSurveyB || (this.score !== undefined && metric.score === undefined)) {
            comparison.isIncreased = true;
            comparison.isPositive = true;
        }

        if (customerSurveyA < customerSurveyB || (this.score === undefined && metric.score !== undefined)) {
            comparison.isDecreased = true;
            comparison.isNegative = true;
        }

        return comparison;
    }
}

export default CustomerSatisfaction;
